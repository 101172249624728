.circle {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  font-size: 1.7rem;
  background-image: radial-gradient(
    circle farthest-corner at 10% 20%,
    rgba(253, 193, 104, 1) 0%,
    rgba(251, 128, 128, 1) 90%
  );
  text-align: center;
  padding-top: 0.8rem;
  border: 2px solid #fff;
  box-shadow: rgb(0 0 0 / 17%) 0px -23px 25px 0px inset,
    rgb(0 0 0 / 15%) 0px -36px 30px 0px inset,
    rgb(0 0 0 / 10%) 0px -79px 40px 0px inset, rgb(0 0 0 / 6%) 0px 2px 1px,
    rgb(0 0 0 / 9%) 0px 4px 2px, rgb(0 0 0 / 9%) 0px 8px 4px,
    rgb(0 0 0 / 9%) 0px 16px 8px, rgb(0 0 0 / 9%) 0px 32px 16px;
  position: relative;
  animation: gradient 20s ease infinite;
  background-size: 400% 400%;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
