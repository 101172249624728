.numAnimate {
  animation: myAnim 3s ease 0ms infinite alternate forwards;
}

.numAnimate1 {
  animation: myAnim 3s ease 0ms infinite alternate forwards;
}

@keyframes myAnim {
  0%,
  50%,
  100% {
    opacity: 1;
  }

  25%,
  75% {
    opacity: 0;
  }
}
