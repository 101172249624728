.animatebg {
  background: linear-gradient(
    68.3deg,
    rgba(245, 177, 97, 1) 0.4%,
    rgba(236, 54, 110, 1) 100.2%
  );
  background-size: 400% 400%;
  /* animation: gradient 10s ease infinite; */
  height: 100vh;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
